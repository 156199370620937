.payment-option-info-container {
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .label {
      font-weight: 300;
      font-size: 16px;
      letter-spacing: 0.64px;
      color: rgba(0, 0, 0, 0.5);
    }
  }

  .credit-card-container {
    .credit-card-number {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .credit-card-label-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .credit-card-icon {
          display: flex;
          align-items: center;

          img {
            height: 14px;
            width: auto;
            margin-right: 7px;
          }
        }

        .credit-card-label {
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0.42px;
          color: #161b2b;
        }
      }

      .credit-card-number {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.42px;
        color: #161b2b;
      }
    }
  }

  .credit-card-expired {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .expired-on-label {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.42px;
      color: rgba(22, 27, 43, 0.5);
    }

    .expired-on-value {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.42px;
      color: rgb(22, 27, 43);
    }
  }

  .card-info-currency-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .currency-label {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.42px;
      color: rgba(22, 27, 43, 0.5);
    }

    .currency-selector-container {
      .currency-selector {
        margin: 0;

        &.MuiInput-underline {
          &::before,
          &::after,
          &:hover:not(.Mui-disabled)::before {
            border-bottom: none;
          }
        }

        .currency-select {
          border-bottom: 1px solid transparent;
          border-radius: 0;
          transition: 0.3s;
        }
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          .currency-selector {
            .currency-select:not([disabled]) {
              border-bottom: 1px solid #005a9b;
            }
          }
        }
      }

      .currency-select {
        padding: 0 14px 0 !important;

        &:focus {
          background: none;
        }
      }

      .currency-select-icon {
        top: 8px;
      }
    }
  }

  button {
    margin-bottom: 8px;
  }
}
