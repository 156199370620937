.currency-selector-container {
  .MuiFormLabel-root {
    color: #7c7c7c;
    font-family: Arial, sans-serif;
    font-weight: 400;
    font-size: 19px; // 19px * 0.75 = 14.25px

    &.Mui-focused {
      color: #005a9b;
    }
  }

  .currency-label {
    top: 7px;
    left: -14px;
  }

  .currency-selector {
    margin-top: 17px;

    .currency-select:focus {
      background: none;
    }

    &.MuiInput-underline {
      &::before {
        border-bottom: 1px solid rgba(0, 0, 0, 0.5);
      }

      &::after {
        border-bottom: 1px solid #005a9b;
      }

      &:hover:not(.Mui-disabled)::before {
        border-bottom: 1px solid rgba(0, 0, 0, 0.5);
      }
    }
  }

  .currency-select-icon {
    top: 12px;
    right: 0;

    .a {
      fill: #005a9b;
    }
  }
}

@-moz-document url-prefix() {
  select {
    margin-left: -4px !important;
  }
}