.payment-option-container {
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    position: relative;

    .label {
      font-weight: 300;
      font-size: 16px;
      letter-spacing: 0.64px;
      color: rgba(0, 0, 0, 0.5);
    }

    .cancel-edit-button {
      border: none;
      background: none;
      margin: 0;
      padding: 0;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 1.25px;
      color: #005a9b;
      cursor: pointer;
    }

    .edit-container {
      position: absolute;
      top: 50%;
      left: calc(100% - 10px);
      transform: translate(-50%, -50%);
      width: 36px;
      height: 36px;
      border-radius: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18.37px;
      color: #005a9b;
      position: absolute;
      transition: 0.3s;

      .a {
        fill: #005a9b;
        transition: 0.3s;
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          cursor: pointer;
          background-color: #005a9b;
          color: #ffffff;

          .a {
            fill: #ffffff;
          }
        }
      }
    }
  }
}
