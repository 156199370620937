.change-plan-container {
  .header-container {
    margin-top: 20px;

    .header {
      font-size: 16px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      margin-bottom: 20px;
      padding: 0;
    }
  }

  .radio-buttons-container {
    .change-plan-form {
      display: flex;
      flex-direction: column;
    }
  }

  .selected-plan-info-container {
    margin-top: 20px;

    .selected-plan-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .selected-plan-label {
        line-height: 32px;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.42px;
        color: rgba(22, 27, 43, 0.5);
      }

      .selected-plan-value-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 20px;
        border: none;
        border-radius: 136px;
        padding: 0 8px;

        &.free {
          background-color: rgba(235, 178, 0, 0.09);
          color: rgba(235, 178, 0, 1);
        }

        &.basic {
          background-color: rgba(5, 183, 173, 0.09);
          color: rgba(5, 183, 173, 1);
        }

        &.pro {
          background-color: rgba(0, 119, 217, 0.09);
          color: rgba(0, 119, 217, 1);
        }

        &.voucher {
          background-color: rgba(0, 119, 217, 0.09);
          color: rgb(0, 90, 155);
        }

        .selected-plan-value {
          font-size: 14px;
          font-weight: 400;
          text-transform: uppercase;
        }
      }
    }

    .amount-per-year-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .amount-per-year-label {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.42px;
        color: rgba(22, 27, 43, 0.5);
      }

      .amount-per-year-value {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .change-plan-buttons-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;

    button {
      margin-top: 0;

      &:not(:first-child) {
        margin-left: 35px;
      }
    }
  }
}

.terms-and-conditions-container {
  margin-top: 6px;

  .terms-and-conditions-label {
    font-size: 14px;
    color: #000;

    a {
      color: #005a9b;
      text-decoration: none;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          color: #005a9b;
          text-decoration: underline;
        }
      }
    }
  }
}
