.custom-select-container {
  width: 100%;
  border: 1px solid #c6c8d2;
  border-radius: 4px;
  transition: 0.3s;

  @media (hover: hover) and (pointer: fine) {
    &:hover,
    &:focus {
      border-color: #005a9b;
    }
  }

  .custom-select {
    font-weight: 500;
    font-size: 14px;
    padding-top: 4px;
    padding-bottom: 6px;
    padding-left: 8px;

    &:focus {
      background: none;
    }
  }

  &.MuiInput-underline {
    &::before {
      border: none;
    }

    &::after {
      border: none;
    }
  }

  .custom-select-icon {
    top: 11px;
    right: 6px;

    .a {
      fill: #005a9b;
    }
  }
}
