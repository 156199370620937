@mixin free {
  background-color: rgba(235, 178, 0, 0.09);
  color: rgb(235, 178, 0);
}

@mixin basic {
  background-color: rgba(5, 183, 173, 0.09);
  color: rgb(5, 183, 173);
}

@mixin pro {
  background-color: rgba(0, 119, 217, 0.09);
  color: rgb(0, 119, 217);
}

@mixin voucher {
  background-color: rgba(0, 119, 217, 0.09);
  color: rgb(0, 90, 155);
}

.my-plan {
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .label {
      font-weight: 400;
      font-size: 16px;
      letter-spacing: 0;
      color: rgba(0, 0, 0, 0.5);
    }

    .plan-type {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 20px;
      border: none;
      border-radius: 136px;
      text-transform: uppercase;
      padding: 0 8px;

      &.free {
        @include free;
      }

      &.basic {
        @include basic;
      }

      &.pro {
        @include pro;
      }

      span {
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 18px;
      }
    }
  }

  .next-payment-container,
  .amount-per-year-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4px;

    .next-payment-label,
    .amount-per-year-label {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.42px;
      color: rgba(22, 27, 43, 0.5);
    }

    .next-payment-value,
    .amount-per-year-value {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.42px;
      color: #161b2b;
    }
  }

  .change-plan-info {
    padding: 3px 8px;
    margin-top: 10px;

    &.free {
      @include free;
    }

    &.basic {
      @include basic;
    }

    &.pro {
      @include pro;
    }

    &.voucher {
      @include voucher;
    }

    span {
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 0.48px;
    }

    &.skeleton {
      padding: 0;
    }
  }

  button {
    margin-top: 20px;
  }
}

@media screen and (min-width: 576px) {
  .my-plan {
    .next-payment-container {
      margin-top: 20px;
    }
  }
}
