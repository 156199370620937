.card-credentials-form-container {
  position: relative;

  .wallee-container {
    margin-left: -16px;
    margin-right: -16px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;

    .wallee {
      width: 100%;
      height: 100%;
    }
  }

  .card-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #f8f8f8;
    padding: 20px 16px;
    margin: 3px -16px 0;

    .card-info-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
    }

    .text-input-container {
      width: 30%;
    }

    .currency-container {
      .flags-select-label {
        margin-bottom: 0;
        color: #005a9b;
        font-family: Poppins, sans-serif;
        font-size: 1rem;
        transform: translate(0, 1.5px) scale(0.75);
        font-weight: 400;
        letter-spacing: 0.39px;
      }

      .react-selector-container {
        .react-selector__control {
          border: none;
          box-shadow: none;
          background-color: inherit;

          &:hover {
            border: none;
            box-shadow: none;
          }

          .react-selector__value-container {
            display: flex;
            padding: 0;
          }

          .react-selector__indicators {
            .react-selector__indicator {
              padding: 0;
              color: #005a9b;
            }
          }
        }

        .react-selector-menu {
          display: block;
        }
      }
    }
  }

  .iframe-currency-selector {
    display: none;
    margin-left: -16px;
    margin-right: -16px;
    padding: 0 15px;
    width: calc(100% / 3 + 10px);
    position: absolute;
    right: 0;
    top: 115px;

    .currency-selector-container {
      width: 100%;
    }
  }

  button {
    margin-top: 8.5px;
  }
}

@media screen and (min-width: 576px) {
  .card-credentials-form-container {
    .wallee-container {
      margin-left: 0;
      margin-right: 0;
    }

    .card-form {
      margin: 3px 0 0;
    }

    .iframe-currency-selector {
      margin-left: 0;
      margin-right: 0;
    }
  }
}