.label {
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 0.64px;
  color: rgba(0, 0, 0, 0.5);
}

.data-wrapper {
  margin-top: 16px;
  
  .label {
    font-size: 14px;
    font-weight: 400;
  }

  .value {
    font-size: 14px;
    color: #161b2b;
  }
}
