*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  min-height: 100% !important;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: #000;
  background-color: #f9f9f9;
}

body {
  position: relative;
}

input[type='text']:hover,
input[type='number']:hover,
input[type='email']:hover,
input[type='tel']:hover,
input[type='password']:hover,
textarea:hover {
  cursor: text;
}

.a {
  fill: #005a9b;
}

.webview-component {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  padding: 20px 16px 20px;
  border: none;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;

  &:last-child {
    margin-bottom: 0;
  }
}

@media (hover: none) {
  body {
    cursor: pointer;
  }
}
