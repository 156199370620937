.payment-error {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;
  min-height: 42px;
  background-color: #d63521;
  color: #fff;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 12px;
  padding: 0 12px;
}

.payment-error-message {
  padding-left: 6.85px;
}

@media screen and (min-width: 576px) {
  .payment-error {
    min-height: 50px;
  }
}
