.modal-backdrop.show {
  opacity: 0.1 !important;
}

.thanks-modal-window-container {
  .thanks-modal-window {
    width: 100vw;
    padding-left: 14px;
    padding-right: 14px;
    margin: auto !important;

    &.modal-dialog {
      max-width: none !important;

      .modal-body {
        padding: 48px 38px;
      }
    }

    .modal-content {
      border: none;
    }
  }
}

@media (min-width: 576px) {
  .thanks-modal-window-container {
    .thanks-modal-window {
      width: 540px;
      padding-left: 0;
      padding-right: 0;

      &.modal-dialog {
        .modal-body {
          padding: 75px 38px;
        }
      }
    }
  }
}
