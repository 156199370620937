.custom-radio {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      cursor: pointer;
    }
  }

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }

  .mh-32 {
    min-height: 32px;
  }

  .checkmark {
    display: none;
  }

  .radio-container {
    min-height: 33px;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      & + .checkmark {
        display: inline-block;
        border: 2px solid #005a9b;
        padding: 4px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        box-sizing: border-box;
        margin: 0 10px 0 0;
        position: relative;
        vertical-align: middle;

        &.disabled {
          opacity: 0.15;
        }

        &::after {
          content: '';
          background: rgba(0, 148, 255, 0);
          border-radius: 50%;
          width: 10px;
          height: 10px;
          display: inline-block;
          position: absolute;
          top: 3px;
          left: 3px;
        }
      }

      &:checked + .checkmark::after {
        background: #005a9b;
      }
    }

    .label-container {
      width: 40px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      &.free {
        background-color: #ebb200;
      }

      &.basic {
        background-color: #05b7ad;
      }

      &.pro {
        background-color: #0077d9;
      }

      &.voucher {
        background-color: #005a9b;
        width: 72px;
      }

      .label {
        text-transform: uppercase;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0;
        color: #fff;
      }
    }
  }

  .description-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 10px;
    width: 100%;

    .description {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.42px;
      color: #161b2b;
    }
  }

  .price-container {
    padding-left: 30px;
    margin-top: 1px;
    width: 100%;

    .MuiInput-underline:hover:not(.Mui-disabled)::before {
      border: none;
    }

    .price {
      font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
      font-size: 14px;
      font-weight: 500;
      color: #161b2b;
      white-space: nowrap;

      width: 100%;
      line-height: 32px;
    }

    .voucher-is-selected {
      flex-direction: column !important;
      .plan-label {
        line-height: 32px;
      }

      .justify-content-sm-end {
        margin-top: 29px;
        font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
        span {
          color: #161b2b7f;
          font-size: 14px;
          margin-top: 10px;
          margin-right: 25px;
        }
      }

      .plan-label,
      .end-on-label {
        color: #161b2b7f;
        font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
        font-size: 14px;
        span {
          font-size: 14px;
          font-weight: 500;
          color: #161b2b;
          white-space: nowrap;
          margin-left: 5px;
        }
      }
    }
  }

  .sub-description-grid {
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0;

    &.free {
      color: #ebb200;
    }

    &.basic {
      color: #05b7ad;
    }

    &.pro {
      color: #0077d9;
    }

    &.voucher {
      color: #005a9b;
    }
  }

  .sub-description {
    position: absolute;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0;

    &.free {
      color: #ebb200;
    }

    &.basic {
      color: #05b7ad;
    }

    &.pro {
      color: #0077d9;
    }

    &.voucher {
      color: #005a9b;
    }
  }

  .sub-description-container {
    height: 12px;
  }

  .sub-description-container-sm {
    line-height: 0;
  }

  .sub-description-container-md {
    display: none;

    .sub-description {
      margin-left: 30px;
    }

    .sub-description-grid {
      margin-left: -40px;

      &.voucher {
        margin-left: -72px;
      }
    }
  }

  .sub-description-wrapper-inner {
    line-height: 0;
  }

  .voucher-is-selected-date {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;

    .voucher-is-select-date-field {
      margin-top: -15px;

      input {
        border: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.5);
        width: 55px;
        margin-left: 30px;
        text-align: center;
        letter-spacing: 1px;
        padding-bottom: 4px;
        font-size: 15px;
      }
    }

    .voucher-is-select-date-label {
      font-size: 14px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.5);
      margin-top: 6px;
    }
  }
}

@media screen and (min-width: 576px) {
  .custom-radio {
    .description-container {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .price-container {
      width: auto;
    }

    .sub-description-container-sm {
      display: none;
    }

    .sub-description-container-md {
      display: flex;
    }
  }
}

.voucher-is-not-selected,
.voucher-is-selected {
  .plan-label {
    line-height: 32px;
  }

  .plan-label,
  .end-on-label {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    color: #161b2b7f;
    font-size: 14px;
    span {
      font-weight: 500;
      color: #161b2b;
      margin-left: 5px;
    }
  }
}

@media screen and (max-width: 576px) {
  .flex-voucher {
    flex-direction: column !important;
    width: 100% !important;
  }

  .end-user-voucher {
    flex-direction: row !important;

    .end-on-label {
      display: flex;
      justify-content: flex-end !important;
    }
  }

  .flex-no-voucher {
    flex-direction: column !important;

    .description-container {
      width: max-content;
    }

    .price {
      display: flex;
      justify-content: flex-end;
    }
  }
  .custom-radio {
    .flex-voucher.voucher-is-selected {
      flex-direction: column !important;
    }
    .flex-no-voucher.voucher-is-selected {
      flex-direction: column !important;

      .price {
        justify-content: flex-start;
      }
    }
    .voucher-is-selected {
      flex-direction: row !important;
      .voucher-is-select-date-label {
        color: #161b2b7f;
      }

      .justify-content-sm-end {
        margin-top: 28px;
      }

      .plan-label {
        line-height: 32px;
      }
      .plan-label,
      .end-on-label {
        font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
        color: #161b2b7f;
        font-size: 14px;
        span {
          font-weight: 500;
          color: #161b2b;
          margin-left: 5px;
        }
      }
    }

    .voucher-is-not-selected {
      flex-direction: row !important;

      .plan-label {
        line-height: 32px;
      }
      .plan-label,
      .end-on-label {
        font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
        color: #161b2b7f;
        font-size: 14px;
        span {
          font-weight: 500;
          color: #161b2b;
          margin-left: 5px;
        }
      }
    }
  }
}
