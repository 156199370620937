.plans {
  margin-bottom: 14.5px;
  position: relative;

  .plans-table {
    border-collapse: separate;
    border-spacing: 1px 10px;
    margin: -10px 0;
    width: 100%;
  }

  .desc {
    white-space: pre-wrap;
  }

  .thead {
    tr {
      th {
        height: 30px;
        padding: 0;
        text-align: center;

        &:first-child {
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0;
          color: rgba(22, 27, 43, 0.5);
          text-align: left;
          background: none;
        }

        &:not(:first-child) {
          width: 40px;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0;
          color: #fff;
          text-transform: uppercase;
        }

        &.helion_free {
          background-color: #ebb200;
        }

        &.helion_basic {
          background-color: #05b7ad;
        }

        &.helion_pro {
          background-color: #0077d9;
        }

        &.voucher {
          background-color: #005a9b;
        }
      }
    }
  }

  .tbody {
    tr {
      td {
        height: 36px;
        text-align: left;

        &.description {
          font-size: 10px;
          font-weight: 400;
          letter-spacing: 0;
          color: #161b2b;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &:not(:first-child) {
          width: 40px;
          font-size: 14px;
          font-weight: 500;
          text-align: center;
          background-color: #f8f8f8;
        }

        &.helion_free {
          color: #eea500;

          & .checkmark {
            fill: #eea500;
          }

          svg {
            path {
              fill: #eea500;
            }
          }
        }

        &.helion_basic {
          color: #05b7ad;

          & .checkmark {
            fill: #05b7ad;
          }

          svg {
            path {
              fill: #05b7ad;
            }
          }
        }

        &.helion_pro {
          color: #0077d9;

          & .checkmark {
            fill: #0077d9;
          }

          svg {
            path {
              fill: #0077d9;
            }
          }
        }

        &.voucher {
          color: #005a9b;

          & .checkmark {
            fill: #005a9b;
          }

          svg {
            path {
              fill: #005a9b;
            }
          }
        }
      }
    }
  }

  .tfoot {
    width: 100%;
    margin-top: 10.5px;
    border-top: 1px solid rgba(22, 27, 43, 0.1);
    border-bottom: 1px solid rgba(22, 27, 43, 0.1);
    vertical-align: top;
    border-collapse: separate;
    padding-bottom: 10.5px;

    tr {
      td {
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
        padding-top: 11px;

        &.description {
          letter-spacing: 0;
          color: #161b2b;
        }

        &:not(:first-child) {
          width: 40px;

          letter-spacing: 0;
          text-align: center;
        }

        &.helion_free {
          color: #161b2b;
        }

        &.helion_basic {
          color: #05b7ad;
        }

        &.helion_pro {
          color: #0077d9;
        }

        &.voucher {
          color: #005a9b;
        }
      }
    }
  }

  .free,
  .basic,
  .pro {
    text-transform: uppercase;
  }

  svg {
    overflow: visible;
  }
}
.hints-wrapper {
  margin-top: 5px;
}
.hints {
  padding-top: 5px;
  color: #161b2b;
  font-size: 10px;
  display: flex;
  margin: 0;
  gap: 5px;
  .hint-0,
  .hint-1 {
    padding-left: 0;
  }
}
.hint-0 {
  padding-left: 2px;
  font-size: 14px;
  color: #005a9b;
}
.hint-1 {
  padding-left: 5px;
  font-size: 14px;
  color: #eea500;
}
