.plan {
  hr {
    width: 100%;
    height: 0;
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
