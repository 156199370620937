.custom-backdrop {
  z-index: 1100 !important;
  overflow: hidden !important;
}

.circular-progress-container {
  &.MuiCircularProgress-colorPrimary {
    color: #005a9b;
  }
}
