.home-page-container {
  .payment-platform-container {
    padding: 15px 14px;
    margin: 0;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;

    .wallee {
      width: 100%;
      height: 100%;
    }
  }
}

.snackbar-provider-container-root {
  position: fixed !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  transform: none !important;
  max-width: 540px !important;
  margin: 0 auto;
  width: 100%;

  & > .MuiCollapse-entered {
    width: 100%;

    .MuiCollapse-wrapper {
      padding: 0 0 4px 0 !important;
    }
  }

  .snackbar-provider-root {
    .snackbar-provider-container {
      padding: 0;
      border-radius: 0;
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      svg {
        margin: 0 10px 0 20px;
      }
    }

    .snackbar-warning {
      background-color: #d63521;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-size: 12px;
      font-weight: 400;
    }
  }
}

.error-internet-connection {
  display: flex;
  align-items: center;
  padding: 17px 20px 11px;
  background-color: #d63521;
}

.error-connection-message {
  margin-left: 11px;
  color: #fff;
  font-size: 12px;
  line-height: 16px;
}

@media screen and (min-width: 576px) {
  .home-page-container {
    width: 540px;
    margin: 0 auto;
    padding: 15px 0 38px;

    .payment-platform-container {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
