.footer-container {
  display: flex;
  justify-content: center;
  padding: 0 14px 19px;
  font-size: 12px;
  color: #000;

  span {
    text-align: center;

    a {
      color: inherit;
      text-decoration: underline;
      transition: 0.1s;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          color: #005a9b;
        }
      }
    }
  }
}

@media screen and (min-width: 576px) {
  .footer-container {
    padding-bottom: 38px;
  }
}
