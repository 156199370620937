.date-field {
  width: 90px;

  input {
    text-align: center;
  }

  .date-error {
    font-size: 11px;
    color: red;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    line-height: 1;
  }

  input {
    border-bottom: 1px solid rgba(0, 0, 0, 0.5) !important;
  }
}
