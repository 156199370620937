.custom-checkbox-container {
  .custom-checkbox-label {
    margin-left: -8px;
  }

  .custom-checkbox-icon {
    .checkbox-border {
      fill: #ffffff;
      stroke: #005a9b;
    }

    .checkbox-checkmark {
      fill: #ffffff;
    }
  }

  .custom-checkbox-checked-icon {
    .checkbox-border {
      fill: #005a9b;
      stroke: #005a9b;
    }

    .checkbox-checkmark {
      fill: #ffffff;
    }
  }

  .disabled-checkbox-hover {
    &:hover {
      background-color: transparent !important;
    }
  }

  .validation-error {
    margin: -10px 0 0 29px;
    font-size: 9px;
    font-weight: 400;
    color: #eb001b;
  }
}
