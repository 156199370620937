.modal-open {
  overflow: auto;
  padding-right: 0 !important;
}

.modal-backdrop {
  &.show {
    opacity: 0.1 !important;
  }
}

.confirm-transaction-modal-container {
  .confirm-transaction-modal {
    width: 100vw;
    padding-left: 14px;
    padding-right: 14px;
    margin: auto !important;

    &.modal-dialog {
      max-width: none !important;

      .modal-body {
        padding: 30px 40px;
      }
    }

    .modal-content {
      border: none;
    }
  }
}

@media screen and (min-width: 576px) {
  .confirm-transaction-modal-container {
    .confirm-transaction-modal {
      width: 540px;
      padding-left: 0;
      padding-right: 0;
    }
  }
}
