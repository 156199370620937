.confirm-iframe-container {
  position: relative;

  .confirm-transaction-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 26px;

    span {
      font-weight: 500;
      font-size: 16px;
      text-align: center;
      color: #000000;
    }
  }

  .wallee-container {
    margin-left: 0;
    margin-right: 0;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    .wallee {
      width: 100%;
      height: 100%;
    }
  }

  .payment-method-brand-logo {
    display: inline;
    height: 14px;
    width: auto;
    margin-left: 7px;
  }

  .confirm-btn-container {
    display: flex;
    justify-content: center;
    margin-top: 18px;
  }

  .cancel-confirmation-btn-container {
    display: flex;
    justify-content: center;
    margin-top: 23px;
  }
}

@media screen and (min-width: 576px) {
  .confirm-iframe-container {
    .payment-method-brand-logo {
      margin-left: 25px;
    }
  }
}
